<!-- Composant affichant un message à l'utilisateur (avertissement, erreur, réussite) -->
<template>
    <v-alert 
        border="left"
        dense 
        dismissible
        text 
        transition="fade-transition"
        :type="type"
        v-if="message != ''">
            {{ message }}
    </v-alert>
</template>
 
<script>

    // Export du composant
    export default {
        computed: {

        },
        props: {
            message: String,
            type: String,
        }
    }

</script>