/* Définition des routes pour les requêtes de l'application */

// Définition des constantes métadonnées
const default_title = "Ludo Trièves";
const url = process.env.VUE_APP_APP_URL;
const default_icon = "favicon.ico";
const link = document.querySelector("[rel='icon']")

// Import des modules
import Vue from "vue";
import Router from "vue-router";

// Routes
Vue.use(Router);

// Publication du module
export const router = new Router({
    mode: "history",
    routes: [
        {
            meta: {
                title: route => { 
                    return "Accueil | Les Dés Calés du Trièves" 
                },
                icon: "favicon.ico",
            },
            name: "home",
            path: "/",
            component: () => import("../views/home.vue")
        },
        {
            meta: {
                title: route => { 
                    return "Accueil | Les Dés Calés du Trièves" 
                },
                icon: "/favicon.ico",
            },
            path: "/home",
            component: () => import("../views/home.vue")
        },
        {
            meta: {
                title: route => { 
                    return "Accueil | Les Dés Calés du Trièves" 
                },
                icon: "favicon.ico",
            },
            name: "home2",
            path: "/home2",
            component: () => import("../views/home2.vue")
        },
        {
            meta: {
                title: route => { 
                    return "Catalogue | Les Dés Calés du Trièves" 
                },
                icon: "/favicon.ico",
            },
            path: "/catalog",
            component: () => import("../views/catalog.vue")
        },
        {
            path: "/chess",
            component: () => import("../views/chess.vue")
        },
        {
            path: "/roles",
            component: () => import("../views/Roles.vue")
        },
        {
            meta: {
                title: route => { 
                    return "Connexion | Les Dés Calés du Trièves" 
                },
                icon: "/favicon.ico",
            },
            path: "/login",
            component: () => import("../views/login.vue")
        },
        {
            path: "/mtg",
            component: () => import("../views/mtg.vue")
        },
        {
            path: "/register",
            component: () => import("../views/Register.vue")
        },
        {
            path: "/profile",
            name: "profile",
            // lazy-loaded
            component: () => import("../views/Profile.vue")
        },
        {
            path: "/admin",
            name: "admin",
            // lazy-loaded
            component: () => import("../views/BoardAdmin.vue")
        },
        {
            path: "/users",
            name: "users",
            // lazy-loaded
            component: () => import("../views/Users.vue")
        },
        {
            path: "/validation",
            name: "validation",
            // lazy-loaded
            component: () => import("../views/Validation.vue")  
        }
    ]
});

// Redirige vers la page d'accueil l'utilisateur s'il tente d'accéder à une page restreinte et qu'il n'est pas connecté
router.beforeEach((to, from, next) => {
    const pages = [
        {name : '/catalog', public : false},
        {name : '/check', public : false},
        {name : '/chess', public : false},
        {name : '/home', public : true},
        {name : '/login', public : true},
        {name : '/mtg', public : false},
        {name : '/register', public : false},
        {name : '/validation', public : true}
    ];
    var auth_required = true;
    var auth_route = false;
    try { 
        for (let page of pages) {
            if (page.name === to.path) {
                auth_required = !page.public;
                auth_route = true;
            }
        }
    } catch(e) {
        console.log(e);
    }
    const log = localStorage.getItem("user");
    if ((to.path != '/home' || !auth_route) && auth_required && !log) {
        next('/home');
    } else {
        next();
    }
});

// Applique les métadonnées à chaque route
router.afterEach((to, from) => {
    Vue.nextTick(() => { 
        document.title = to.meta.title ? to.meta.title(to) : default_title;
        link.setAttribute('href', (to.meta.icon) ? (url + to.meta.icon) : (url + default_icon));
    });
});
