export default function auth_header() {
  let user = JSON.parse(localStorage.getItem("user"));
  if (user && user.token) {
    // for Node.js Express back-end
    return {
        'Authorization': 'Bearer '+user.token,
        'Content-Type': 'application/json', 
        /* 'x-access-token': user.token */ 
    };
  } else {
    return {};
  }
}
