import Vue from "vue";
import App from "./App.vue";
import { router } from "./router";
import store from "./store";
import VeeValidate from "vee-validate";
import Axios from "axios";
import VueAxios from "vue-axios";
import vuetify from "./plugins/vuetify";

Vue.use(VeeValidate);
Vue.use(VueAxios, Axios);
Vue.use(vuetify);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
