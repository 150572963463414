import { render, staticRenderFns } from "./notify.vue?vue&type=template&id=6fbcd0be&scoped=true"
import script from "./notify.vue?vue&type=script&lang=js"
export * from "./notify.vue?vue&type=script&lang=js"
import style0 from "./notify.vue?vue&type=style&index=0&id=6fbcd0be&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fbcd0be",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
