<template>

    <!-- Vérification URL -->
    <v-app id="alert_app" v-if="!original_url">
        <v-alert type="error" >Halte au piratage !</v-alert>
    </v-app>

    <!-- Application -->
    <v-app id="app" v-else-if="original_url">
        
        <!-- Composant notifications-->
        <notify/>

        <!-- Barre de navigation -->
        <v-navigation-drawer app temporary v-model="navigation_drawer">
            <v-list nav>
                <v-subheader>TRIEVES.GAMES</v-subheader>
                <v-divider></v-divider>
                <v-list-item-group color="green darken-3">
                    <v-list-item to="/home">
                        <v-list-item-icon><v-icon>home</v-icon></v-list-item-icon>
                        <v-list-item-content>Accueil</v-list-item-content>
                    </v-list-item>
                    <v-list-item to="/home2" v-if="is_current_user(['webmaster'])">
                        <v-list-item-icon><v-icon>home</v-icon></v-list-item-icon>
                        <v-list-item-content>Accueil (dev.)</v-list-item-content>
                    </v-list-item>
                    <v-list-item href="https://www.myludo.fr/#!/profil/la-ludotheque-du-trieves-9099" target="_blank">
                        <v-list-item-icon><v-icon>mdi-book-open-page-variant-outline</v-icon></v-list-item-icon>
                        <v-list-item-content>Catalogue</v-list-item-content>
                    </v-list-item>
                    <v-list-item to="/catalog" v-if="is_current_user(['webmaster'])">
                        <v-list-item-icon><v-icon>mdi-book-open-page-variant-outline</v-icon></v-list-item-icon>
                        <v-list-item-content>Catalogue (dev.)</v-list-item-content>
                    </v-list-item>
                    <v-list-item to="/chess" v-if="is_current_user(['admin', 'webmaster'])">
                        <v-list-item-icon><v-icon>mdi-chess-king</v-icon></v-list-item-icon>
                        <v-list-item-content>Echecs</v-list-item-content>
                    </v-list-item>
                    <v-list-item to="/mtg" v-if="is_current_user(['admin', 'webmaster'])">
                        <v-list-item-icon><v-icon>mdi-auto-fix</v-icon></v-list-item-icon>
                        <v-list-item-content>Magic</v-list-item-content>
                    </v-list-item>
                    <v-list-item to="/profile" v-if="log">
                        <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
                        <v-list-item-content>{{ current_user.email }}</v-list-item-content>
                    </v-list-item>
                    <v-list-item to="/register" v-if="!log && is_current_user(['admin', 'webmaster'])">
                        <v-list-item-icon><v-icon>apps</v-icon></v-list-item-icon>
                        <v-list-item-content>S'inscrire</v-list-item-content>
                    </v-list-item>
                    <v-list-item to="/roles" v-if="is_current_user(['admin', 'webmaster'])">
                        <v-list-item-icon><v-icon>mdi-lock</v-icon></v-list-item-icon>
                        <v-list-item-content>Rôles</v-list-item-content>
                    </v-list-item>
                    <v-list-item to="/users" v-if="is_current_user(['admin', 'webmaster'])">
                        <v-list-item-icon><v-icon>mdi-account-supervisor</v-icon></v-list-item-icon>
                        <v-list-item-content>Utilisateurs</v-list-item-content>
                    </v-list-item>
                    <v-list-item @click.prevent="change_log_status" v-if="log">
                        <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
                        <v-list-item-content>Se déconnecter</v-list-item-content>
                    </v-list-item>
                    <v-list-item to="/login" v-if="!log">
                        <v-list-item-icon><v-icon>mdi-login</v-icon></v-list-item-icon>
                        <v-list-item-content>Se connecter</v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>

        <!-- Barre d'application -->
        <v-app-bar app color="white" flat prominent height="300" shrink-on-scroll src="./assets/img/logo.jpg">
            <template v-slot:img="{ props }">
                <v-img contain v-bind="props"></v-img>
            </template>
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn @click.stop="navigation_drawer = !navigation_drawer" color="green darken-3" dark icon v-bind="attrs" v-on="on" v-show="display_menu">
                        <v-icon>menu</v-icon>
                    </v-btn>
                </template>
                <span>Menu</span>
            </v-tooltip>
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="green darken-3" dark icon to="/home" v-bind="attrs" v-on="on" v-show="!display_menu">
                        <v-icon>home</v-icon>
                    </v-btn>
                </template>
                <span>Accueil</span>
            </v-tooltip>
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="green darken-3" dark icon to="/home2" v-bind="attrs" v-on="on" v-show="!display_menu && is_current_user(['webmaster'])">
                        <v-icon>home</v-icon>
                    </v-btn>
                </template>
                <span>Accueil (dev.)</span>
            </v-tooltip>
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="green darken-3" dark href="https://www.myludo.fr/#!/profil/la-ludotheque-du-trieves-9099" icon target="_blank" v-bind="attrs" v-on="on" v-show="!display_menu">
                        <v-icon>mdi-book-open-page-variant-outline</v-icon>
                    </v-btn>
                </template>
                <span>Catalogue</span>
            </v-tooltip>
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="green darken-3" dark icon to="/catalog" v-bind="attrs" v-on="on" v-show="!display_menu && is_current_user(['webmaster'])">
                        <v-icon>mdi-book-open-page-variant-outline</v-icon>
                    </v-btn>
                </template>
                <span>Catalogue (dev.)</span>
            </v-tooltip>
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="green darken-3" dark icon to="/profile" v-bind="attrs" v-on="on" v-show="!display_menu && log">
                        <v-icon>mdi-account</v-icon>
                    </v-btn>
                </template>
                <span>Profil{{ log ? (" de " + current_user.email) : ("") }}</span>
            </v-tooltip>
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="green darken-3" dark icon to="/register" v-bind="attrs" v-on="on" v-show="!display_menu && is_current_user(['admin', 'webmaster'])">
                        <v-icon>mdi-fountain-pen-tip</v-icon>
                    </v-btn>
                </template>
                <span>S'inscrire</span>
            </v-tooltip>
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="green darken-3" dark icon to="/roles" v-bind="attrs" v-on="on" v-show="!display_menu && is_current_user(['admin', 'webmaster'])">
                        <v-icon>mdi-lock</v-icon>
                    </v-btn>
                </template>
                <span>Rôles</span>
            </v-tooltip>
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="green darken-3" dark icon to="/users" v-bind="attrs" v-on="on" v-show="!display_menu && is_current_user(['admin', 'webmaster'])">
                        <v-icon>mdi-account-supervisor</v-icon>
                    </v-btn>
                </template>
                <span>Utilisateurs</span>
            </v-tooltip>
             <v-tooltip bottom class="mr-2">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="green darken-3" dark icon v-bind="attrs" v-on="on" @click.prevent="change_log_status" v-show="!display_menu">
                        <v-icon>{{log ? 'mdi-logout' : 'mdi-login'}}</v-icon>
                    </v-btn>
                </template>
                <span>{{log ? 'Se déconnecter' : 'Se connecter'}}</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="green darken-3" dark icon to="/chess" v-bind="attrs" v-on="on" v-show="!display_menu && log">
                        <v-icon>mdi-chess-king</v-icon>
                    </v-btn>
                </template>
                <span>Échecs</span>
            </v-tooltip>
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="green darken-3" dark icon to="/mtg" v-bind="attrs" v-on="on" v-show="!display_menu && log">
                        <v-icon>mdi-auto-fix</v-icon>
                    </v-btn>
                </template>
                <span>Magic</span>
            </v-tooltip>
            <v-tooltip bottom class="mr-2">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="green darken-3" dark href="https://discord.gg/G3TcKTxC3y" icon target="_blank" v-bind="attrs" v-on="on">
                        <img src="./assets/img/discord.svg" width="30"/>
                    </v-btn>
                </template>
                <span>Discord</span>
            </v-tooltip>
            <v-tooltip bottom class="mr-2">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="contact_dialog=!contact_dialog" color="green darken-3" dark icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-email-edit-outline</v-icon>
                    </v-btn>
                </template>
                <span>Contact</span>
            </v-tooltip>
            <v-tooltip bottom v-if="log">
                <template v-slot:activator="{ on, attrs }">
                    <v-progress-circular
                        class="text-caption ml-4"
                        :color="log_timer_percent_count > 90 ? 'red' : (log_timer_percent_count > 75 ? 'orange' : 'green darken-3')"
                        :rotate="-90"
                        :size="50"
                        :width="5"
                        :value="log_timer_percent_count"
                        v-bind="attrs"
                        v-on="on">
                            {{[new Date(log_timer_token_expiration_delay - log_timer_time_count).getMinutes(),new Date(log_timer_token_expiration_delay - log_timer_time_count).getSeconds()].map(leading_zero).join(':')}}
                    </v-progress-circular>
                </template>
                <span>Temps de connexion restant</span>
            </v-tooltip>
            
        </v-app-bar>
       
        <!-- Contenu de l'application -->
        <v-main class="pa-0" style="margin: 300px 0px 0px !important">
                <router-view />
        </v-main>
        
        <!-- Pied de page -->
        <!-- 
        <v-footer app class="pa-0 ma-0" style="height: 5vh; min-height: 28px;">
            <span>(c) copyright - Biolostat SARL 2020</span>
        </v-footer> 
        -->
        
        <!-- Formulaire de contact -->
        <v-dialog v-model="contact_dialog" :width="dialog_width">
            <v-card>
                <v-overlay :value="contact_dialog_loader">
                    <v-progress-circular color="green darken-3" indeterminate size="64"></v-progress-circular>
                </v-overlay>
                <v-card-title style="padding: 16px !important;">Écrivez-nous !</v-card-title>
                <v-card-text style="padding: 0px 16px !important;">
                    <alert :message="contact_form_message" :type="contact_form_message_type"/>
                    <v-form lazy-validation name="contact_form" ref="contact_form" v-model="contact_form_valid">
                        <v-row style="margin:0 !important;">
                            <v-col :cols="dialog_cols_width" style="padding:0 !important;">
                                <v-text-field
                                    color="green darken-3"
                                    dense
                                    label="NOM"
                                    name="name"
                                    outlined
                                    required
                                    :rules="[contact_form_rules.required]"
                                    v-model="contact.name">
                                </v-text-field>
                                <v-text-field
                                    color="green darken-3"
                                    dense
                                    label="Prénom"
                                    name="first_name"
                                    outlined
                                    required
                                    :rules="[contact_form_rules.required]"
                                    v-model="contact.first_name">
                                </v-text-field>
                                <v-text-field
                                    color="green darken-3"
                                    dense
                                    label="Mail"
                                    name="email"
                                    outlined
                                    required
                                    :rules="[contact_form_rules.required, contact_form_rules.email]"
                                    v-model="contact.email">
                                </v-text-field>
                                <v-text-field
                                    color="green darken-3"
                                    dense
                                    label="Quel mois sommes nous ?"
                                    name="captcha"
                                    outlined
                                    placeholder="Si vous n'êtes pas un robot ;-)"
                                    required
                                    :rules="[contact_form_rules.required, contact_form_rules.captcha]"
                                    v-model="contact.captcha">
                                </v-text-field>
                                <v-textarea
                                    color="green darken-3"
                                    label="Message"
                                    name="message"
                                    outlined
                                    required
                                    :rules="[contact_form_rules.required]"
                                    :rows="dialog_textaera_rows"
                                    v-model="contact.message">
                                </v-textarea>
                            </v-col>            
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions style="padding: 0px 16px 8px 16px !important;">
                    <v-spacer></v-spacer>
                    <v-btn @click="contact_cancel" color="error">Annuler</v-btn>
                    <v-btn @click="contact_save" color="success" :disabled="!contact_form_valid || Object.keys(contact).length !== 5" depressed>Envoyer</v-btn>
                </v-card-actions>  
            </v-card>
        </v-dialog>

        <!-- Message de fin de session -->
        <v-dialog v-model="logout_dialog" :width="dialog_width">
            <v-card>
                <v-card-title>
                    {{log_timer_time_count === log_timer_token_expiration_delay ? 'Fin de session' : 'Confirmation'}}
                </v-card-title>
                <v-card-text>
                    {{log_timer_time_count === log_timer_token_expiration_delay ? 'Vous avez été déconnecté de l\'application' : 'Voulez-vous vraiment vous déconnecter ?'}}
                </v-card-text>  
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="green darken-3"
                      dark
                      text
                      @click="logout">
                        {{log_timer_time_count === log_timer_token_expiration_delay ? 'Ok' : 'Oui'}}
                    </v-btn>
                    <v-btn
                      color="green darken-3"
                      dark
                      text
                      v-if="log_timer_time_count !== log_timer_token_expiration_delay"
                      @click="logout_dialog = false">
                        Non
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Page loader -->
        <v-overlay color="white" :opacity="1" :value="loader">
            <v-progress-circular color="green darken-3" indeterminate size="64"></v-progress-circular>
        </v-overlay>

    </v-app>

</template>

<script>

// Définition de variables
var accepted_url = ['http://192.168.1.171:8080', 'http://localhost:8080', 'http://trieves.games', 'http://www.trieves.games', 'https://trieves.games', 'https://www.trieves.games'];
var log_timer_interval;
var log_timer_step = 1000;

// Import d'autres composants - les rajouter dans 'components' et les appeler par balise : <name></name>
import alert from "./components/alert.vue";
import { mapActions } from 'vuex';
import notify from './components/notify';
import user_service from "./services/user.service";

// Export du composant
export default {
    beforeDestroy() {
        window.removeEventListener('touchstart', this.scroll, {passive:false});
        window.removeEventListener('resize', this.screen);
    },
    components: {
        alert,
        notify
    },
    computed: {
        current_user() {
            return this.$store.state.auth.user;
        },
        dialog_cols_width() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return 12
                case 'sm': return 12
                case 'md': return 12
                case 'lg': return 12
                case 'xl': return 12
                default: return 12
            }
        },
        dialog_textaera_rows() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return 4
                case 'sm': return 6
                case 'md': return 6
                case 'lg': return 8
                case 'xl': return 8
                default: return 4
            }
        },
        dialog_width() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return '90%'
                case 'sm': return '80%'
                case 'md': return '70%'
                case 'lg': return '40%'
                case 'xl': return '30%'
                default: return '75%'
            }
        },
        display_menu() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return true
                case 'sm': return true
                case 'md': return false
                case 'lg': return false
                case 'xl': return false
                default: false
            }
        },
        log() {
            return this.$store.state.auth.status.log;
        },
        original_url() {
            var current_url = document.location.href; 
            var current_domain = current_url.substring( 0 ,current_url.lastIndexOf( "/" ) );
            if (accepted_url.indexOf(current_domain) == -1) {
                return false;
            } else {
                return true;
            }
        },
        route() {
            return this.$route.path;
        }
    },
    created() {
        window.addEventListener('touchstart', this.scroll, {passive:false});
        window.addEventListener('resize', this.screen);
    },
    data () {
        return {
            contact: {},
            contact_dialog: false,
            contact_dialog_loader: false,
            contact_form_message: '',
            contact_form_message_type: '',
            contact_form_rules: {
                captcha: v => /^[a-zA-ZéûÉÛ]+$/.test(v) || 'En toutes lettres',
                email: v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Saisir une adresse valide.',
                required: v => !!v || 'Champ obligatoire'
            },
            contact_form_valid: false,
            loader: true,
            logout_dialog: false,
            log_timer_token_expiration_delay: 0,
            log_timer_time_count: 0,
            log_timer_percent_count: 0,
            navigation_drawer: false,
            overflow: 'hidden',
        };
    },
    mounted: async function() {
        this.initialize();
        await this.$nextTick(function () {
            var t = setTimeout(() => {
                this.loader = false;
                document.documentElement.style.setProperty("--overflow", 'auto');
                window.removeEventListener('touchstart', this.scroll);
            }, 2000);
        });
    },
    watch: {
        log: 'set_log_timer',
        route: 'route_watcher'
    },
    methods: {
        change_log_status() {
            if (this.log) {
                this.logout_dialog = true;
            } else {
                if (this.$route.path !== '/login') {this.$router.push('/login')}
            } 
        },
        contact_cancel() {
            this.contact_dialog=false;
            this.$refs.contact_form.reset();
            this.contact={};
            this.contact_form_message = '';
            this.contact_form_message_type = ''
        },
        contact_save() {
            this.contact_dialog_loader = true;
            user_service.contact(this.contact).then(response => {
                this.contact_cancel();
                this.contact_dialog_loader = false;
                this.notify({message: response.data.message, type:"success"});
            },
            error => {
                if(error.response.data.error){
                    this.contact_dialog_loader = false;
                    this.contact_form_message = 
                        (error.response && error.response.data.error) ||
                        error.message ||
                        error.toString();
                    this.contact_form_message_type = 'error'
                } else {
                    this.contact_dialog_loader = false;
                    this.contact_form_message = 
                        (error.response && error.response.data.warning) ||
                        error.message ||
                        error.toString();
                        this.contact_form_message_type = 'warning'
                }
            });
        },
        initialize() {
            this.screen();
            this.set_log_timer();
        },
        leading_zero(number) {
            return `0${number}`.slice(-2);
        },
        ...mapActions(['notify', 'screen']),
        route_watcher() {
            if (this.route.indexOf('/validation') !== - 1) {
                this.logout();
            }
        },
        scroll(e) {
            e.preventDefault();
        },
        set_log_timer() {
            switch (this.log) {
                case true : 
                    var log_timer_now = new Date().getTime();
                    var log_timer_token_expiration_time = this.current_user.token_exp;
                    var log_timer_token_validity = this.current_user.token_validity;
                    this.log_timer_token_expiration_delay = Math.max(0, log_timer_token_expiration_time - log_timer_now)
                    var log_timer_time_spent = (log_timer_token_validity - this.log_timer_token_expiration_delay) / log_timer_token_validity * 100;
                    if (this.log_timer_token_expiration_delay > 0) {
                        log_timer_interval = setInterval(() => {
                                this.log_timer_time_count = Math.min(this.log_timer_token_expiration_delay, this.log_timer_time_count + log_timer_step)
                                log_timer_time_spent = log_timer_time_spent + log_timer_step / log_timer_token_validity * 100
                                this.log_timer_percent_count = Math.min(100, Math.max(Math.ceil(this.log_timer_percent_count), Math.ceil(log_timer_time_spent)))
                                if (this.log_timer_time_count === this.log_timer_token_expiration_delay) {
                                    this.clear_log_timer();
                                    this.logout_dialog = true;
                                }
                            }, log_timer_step);
                    } else {
                        this.clear_log_timer();
                        this.logout_dialog = true;
                    }    
                    break;
                case false : 
                    this.clear_log_timer();
                    break;
            }
        },
        clear_log_timer() {
            this.log_timer_token_expiration_delay= 0;
            this.log_timer_time_count= 0;
            this.log_timer_percent_count= 0;
            clearInterval(log_timer_interval);
        },
        logout() {
            this.log_timer_token_expiration_delay= 0,
            this.log_timer_time_count= 0,
            this.log_timer_percent_count= 0,
            this.logout_dialog = false;
            this.$store.dispatch('auth/logout');
            if (this.$route.path !== '/home' && this.$route.path.indexOf('/validation') === -1) {this.$router.push('/home')}
        },
        is_current_user(roles) {
            if (this.current_user && this.current_user.role && roles.includes(this.current_user.role)) {
                return true;
            } else {
                return false;
            }
        }
    }
};
</script>

<style>

    /* Sélectionne l'élément racine du document */
    :root {
        --overflow: hidden; /* Variable pour gérer la capacité de scroll en cas d'overlay */
    }

    /* Sélectionne la balise <html> */
    html {
        overflow-y: var(--overflow) !important; /* Définition dynamique de la capacité de scroll */
    }

</style>
