import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

import fr from 'vuetify/es5/locale/fr'

export default new Vuetify({
    lang: {
        locales: { fr },
        current: 'fr',
    },
    iconfont: 'mdi'
});
