// Import des modules
import Vue from "vue";
import Vuex from "vuex";
import { auth } from "./auth.module";
// Déclaration des constantes et des variables
var { v4: uuidv4 } = require('uuid'); // Générateur d'identifiant unique
// Utilisation du magasin
Vue.use(Vuex);
// Export du module magasin
export default new Vuex.Store({
  actions: {
    // Notifications
    notify({commit, state}, data) {
      if (state.nl.length == state.nm) {
        commit('ndelfirst');
      }
      if (data.id) {
        commit('ndel', data);
      } else {
        setTimeout(() => {
          commit('ndel', data)
        }, state.nd);
        commit('nadd', data);
      }
    },
    // Taille de l'écran
    screen({commit, state}) {
      commit('s');
    },
  }, 
  modules: {
    auth
  },
  mutations: {
    // Notifications
    nadd(state, data) {
      let newid = uuidv4(); // Identifiant unique de la notification
      data.id = newid;
      state.nl.push(data);
    },
    ndel(state, data) {
      let index = state.nl.findIndex(n => n.id === data.id);
      if (index != -1) {
        state.nl.splice(index, 1);
      }
    },
    ndelfirst(state) {
      state.nl.splice(0, 1);
    },
    // Taille de l'écran
    s(state) {
      state.sw = window.innerWidth;
      if (state.sw < 480) {
        state.sc = 'smartphone';
      }
      if (state.sw >= 480 && state.sw < 768) {
        state.sc = 'smartphone landscape';
      }
      if (state.sw >= 768 && state.sw < 1024) {
        state.sc = 'tablet';
      }
      if (state.sw >= 1024 && state.sw < 1224) {
        state.sc = 'tablet landscape';
      }
      if (state.sw >= 1224 && state.sw < 1824) {
        state.sc = 'desktop';
      }
      if (state.sw >= 1824) {
        state.sc = 'large';
      }
    }
  },
  state: {
    // Notifications
    nl: [], // Liste des notifications
    nd: 10000, // Délai d'affichage des notifications (en millisecondes)
    nm: 3, // Nombre maximum de notifications affichées
    // Taille de l'écran
    sc: '', // Catégorie d'écran
    sw: window.innerWidth, // Largeur de l'écran
  }
});
