<!-- HTML -->
<template>
    <!-- Container des notifications -->
    <div class="notifications-wrapper" v-show="nl.length > 0">
        <div class="notification" :class="'notification-'+n.type" :key="n.id" v-for="n in nl">
            <v-icon color="white" v-if="n.type=='error'">mdi-close-circle-outline</v-icon>
            <v-icon color="white" v-if="n.type=='success'">mdi-check-circle-outline</v-icon>
            <v-icon color="white" v-if="n.type=='warning'">mdi-alert-circle-outline</v-icon>
            <span>{{ n.message }}</span>
        </div>
    </div>
</template>
<!-- Javascript -->
<script>
    // Import des modules
    import { mapActions, mapState } from 'vuex'
    // Déclaration des constantes et des variables
    // Export du composant "notify"
    export default {
        components: {
        },
        computed: {
            ...mapState(['nl'])
        },
        created() {
        },
        data() {
            return {
            };
        },
        destroyed: function() {
        },
        methods: {
            ...mapActions(['notify'])
        },
        mounted() {
        },
        name: 'notify',
        watch: {
        },
    }
</script>
<!-- CSS -->
<style scoped>
    /* Style du container des notifications */
    .notifications-wrapper {
        bottom: 0;
        display: flex;
        flex-flow: column-reverse;
        height: 50vh;
        right: 0;
        width: 400px;
        padding: 10px;
        position: fixed;
        z-index: 100;
    }
    /* Style des notifications */
    .notification {
        align-items: center;
        border-radius: 4px;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        min-height: 50px;
        opacity: 1;
        padding: 10px;
    }
    .notification > span{   
    }
    .notification-error{
        background-color: #e54d42;
        border-left: 10px solid #b82e24;
        color: white;
    }
    .notification-success{
        background-color: #68cd86;
        border-left: 10px solid #42a85f;
        color: white;
    }
    .notification-warning{
        background-color: #ffb648;
        border-left: 10px solid #f48a06;
        color: white;
    }   
</style>