import axios from "axios";
import auth_header from "./auth.header";

const API_URL = process.env.VUE_APP_API_URL;

class user_service {
    
    add_item(collection, document) {
        return axios.put(API_URL + collection + "/add", document, { headers: auth_header() });
    }

    contact(document) {
        return axios.post(API_URL + "contact", document, { headers: auth_header() });
    }
    
    del_item(collection, document) {
        return axios.post(API_URL + collection + "/del", document, { headers: auth_header() });
    }
    
    desactivate_item(collection, document) {
        return axios.post(API_URL + collection + "/desactivate", document, { headers: auth_header() });
    }
    
    get_all(collection) {
        return axios.get(API_URL + collection + "/all", { headers: auth_header() });
    }
    
    get_public() {
        return axios.get(API_URL + "users/all", { headers: auth_header() });
    }
    
    reactivate_item(collection, document) {
        return axios.post(API_URL + collection + "/reactivate", document, { headers: auth_header() });
    }
    
    update_item(collection, old_document, new_document) {
        return axios.put(API_URL + collection + "/update", {old_doc: old_document, new_doc: new_document}, { headers: auth_header() });
    }

    start(alias) {
		return axios.put(API_URL + "chess/start", alias, {'Content-Type': 'application/json'});
	}
	move(motion) {
		return axios.put(API_URL + "chess/move", motion, {'Content-Type': 'application/json'});
	}	
	meet(alias){
		return axios.put(API_URL + "chess/meet", alias, {'Content-Type': 'application/json'});
	}
    
    /* getUserBoard() {
        return axios.get(API_URL + "users/user", { headers: auth_header() });
    }

    getBoard(role) {
        return axios.get(API_URL + "users/" + role, { headers: auth_header() });
    }

    getAdminBoard() {
        return axios.get(API_URL + "users/admin", { headers: auth_header() });
    } */
}

export default new user_service();
