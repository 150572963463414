import axios from "axios";

const AUTH_URL = process.env.VUE_APP_AUTH_URL;

class auth_service {
    
  login(user) {  
    return axios
      .post(AUTH_URL + "signin/", {
        email: user.email,
        password: user.password
      })
      .then(response => {
        if (response.data.token) {
            localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response;
      });
  }

  logout() {
        localStorage.removeItem("user");
  }

  register(user) {
    return axios.post(AUTH_URL + "signup/", {
      username: user.username,
      email: user.email,
      password: user.password
    });
  }
    
    validation(validation_obj) {
        return axios.put(AUTH_URL + "validation/", {
            password: validation_obj.password,
            token: validation_obj.token
        });
    }
}

export default new auth_service();
