import auth_service from "../services/auth.service";

const user = JSON.parse(localStorage.getItem("user"));
const initial_state = user
  ? { status: { log: true, validate: true }, user }
  : { status: { log: false, validate: false }, user: null };

export const auth = {
  namespaced: true,
  state: initial_state,
  actions: {
    login({ commit }, user) {
        return auth_service.login(user).then(
            response => {
                commit("login_success", response.data);
                return Promise.resolve(response);
            },
            error => {
                commit("login_failure");
            return Promise.reject(error);
            }
        );
    },
    logout({ commit }) {
        auth_service.logout();
        commit("logout");
    },
    register({ commit }, user) {
      return auth_service.register(user).then(
        response => {
          commit("register_success");
          return Promise.resolve(response);
        },
        error => {
          commit("register_failure");
          return Promise.reject(error);
        }
      );
    },
      validation({ commit }, validation_obj) {
        return auth_service.validation(validation_obj).then(
            response => {
                commit("validation_success", response.data);
                return Promise.resolve(response);
            },
            error => {
                commit("validation_failure");
            return Promise.reject(error);
            }
        );
    }
  },
  mutations: {
    login_success(state, user) {
        state.status.log = true;
        state.user = user;
    },
    login_failure(state) {
        state.status.log = false;
        state.user = null;
    },
    logout(state) {
        state.status.log = false;
        state.user = null;
    },
    register_success(state) {
        state.status.log = false;
    },
    register_failure(state) {
        state.status.log = false;
    },
    validation_failure(state) {
        state.status.validate = false;
    },
    validation_success(state) {
        state.status.validate = true;
    }
  }
};
